//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TwoStepCaptcha from '@/components/tools/TwoStepCaptcha'
import { mapActions } from 'vuex'
import { tenantListTenants } from '@/api/modular/tenant/tenantInfoManage'
import { getTenantOpen, getSmsCaptcha, getCaptchaOpen } from '@/api/modular/system/loginManage'
import Verify from '@/components/verifition/Verify'

export default {
  components: {
    TwoStepCaptcha,
    Verify
  },
  data () {
    var captchaTypeValue = 'clickWord'
    var min = 0
    var max = 100
    var random = Math.floor(Math.random() * (max - min)) + min

    if (random % 2 === 0) {
      captchaTypeValue = 'blockPuzzle'
    }
    if (random % 2 === 1) {
      captchaTypeValue = 'clickWord'
    }
    return {
      customActiveKey: 'tab1',
      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      isLoginError: false,
      requiredTwoStepCaptcha: false,
      stepCaptchaVisible: false,
      form: this.$form.createForm(this),
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false
      },
      accountLoginErrMsg: '',
      tenantOpen: false,
      captchaOpen: false, // 是否開啟驗證碼
      tenantsList: [],
      loginParams: [], // 登錄參數
      captchaType: captchaTypeValue
    }
  },
  created () {
    this.getTenantOpen()
    this.getCaptchaOpen()
  },
  methods: {
    ...mapActions(['Login', 'Logout', 'dictTypeData']),
    /**
     * 獲取驗證碼開關
     */
    getCaptchaOpen () {
      getCaptchaOpen().then((res) => {
        if (res.success) {
          this.captchaOpen = res.data
        }
      })
    },
    /**
     * 獲取租戶開關並取得租戶列表
     */
    getTenantOpen () {
      getTenantOpen().then((res) => {
        if (res.success) {
          this.tenantOpen = res.data
          if (res.data) {
            tenantListTenants().then((res) => {
              this.tenantsList = res.data
            })
          }
        }
      })
    },
    // handler
    handleUsernameOrEmail (rule, value, callback) {
      const { state } = this
      const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
      if (regex.test(value)) {
        state.loginType = 0
      } else {
        state.loginType = 1
      }
      callback()
    },
    handleTabClick (key) {
      this.isLoginError = false
      this.customActiveKey = key
      // this.form.resetFields()
    },
    handleSubmit (e) {
      e.preventDefault()
      const {
        form: { validateFields },
        state,
        customActiveKey,
        Login
      } = this

      state.loginBtn = true
      const validateFieldsKey = customActiveKey === 'tab1' ? ['account', 'password'] : ['mobile', 'captcha']
      if (this.tenantOpen) {
        validateFieldsKey.push('tenantCode')
      }
      validateFields(validateFieldsKey, { force: true }, (err, values) => {
        this.loginParams = values
        if (!err) {
          // 是否開啟驗證碼
          if (this.captchaOpen) {
            this.$refs.verify.show()

            state.loginBtn = false
            return
          }
          const loginParams = { ...values }
          delete loginParams.account
          loginParams.account = values.account
          if (this.tenantOpen) {
            loginParams.tenantCode = values.tenantCode
          }
          Login(loginParams)
            .then((res) => this.loginSuccess(res))
            .catch(err => this.requestFailed(err))
            .finally(() => {
              state.loginBtn = false
            })
        } else {
          setTimeout(() => {
            state.loginBtn = false
          }, 600)
        }
      })
    },
    /**
     * 獲取驗證碼
     */
    verifySuccess(params) {
      this.loginParams.code = params.captchaVerification
      this.Login(this.loginParams).then((res) => this.loginSuccess(res))
        .catch(err => this.requestFailed(err))
        .finally(() => {
          this.state.loginBtn = false
        })
    },
    getCaptcha (e) {
      e.preventDefault()
      const { form: { validateFields }, state } = this

      validateFields(['mobile'], { force: true }, (err, values) => {
        if (!err) {
          state.smsSendBtn = true

          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              state.time = 60
              state.smsSendBtn = false
              window.clearInterval(interval)
            }
          }, 1000)

          const hide = this.$message.loading('驗證碼發送中..', 0)
          getSmsCaptcha({ mobile: values.mobile }).then(res => {
            setTimeout(hide, 2500)
            this.$notification['success']({
              message: '提示',
              description: '驗證碼獲取成功，您的驗證碼為：' + res.result.captcha,
              duration: 8
            })
          }).catch(err => {
            setTimeout(hide, 1)
            clearInterval(interval)
            state.time = 60
            state.smsSendBtn = false
            this.requestFailed(err)
          })
        }
      })
    },
    stepCaptchaSuccess () {
      this.loginSuccess()
    },
    stepCaptchaCancel () {
      this.Logout().then(() => {
        this.loginBtn = false
        this.stepCaptchaVisible = false
      })
    },
    loginSuccess (res) {
      this.$router.push({ path: '/' })
      this.isLoginError = false
      // 加載字典所有字典到緩存中
      this.dictTypeData().then((res) => { })
    },
    requestFailed (err) {
      this.accountLoginErrMsg = err
      this.isLoginError = true
    }
  }
}
