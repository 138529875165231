//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { DEVICE_TYPE } from '@/utils/device'
import { mapActions } from 'vuex'
export default {
    data() {
        return {
            DEVICE_TYPE,
            form: this.$form.createForm(this),
            state: {
                time: 60,
                loginBtn: false,
                // login type: 0 email, 1 username, 2 telephone
                loginType: 0,
                smsSendBtn: false,
                loginParams: [], // 登錄參數
            },
        }
    },
    created() {},
    methods: {
        ...mapActions(['Login', 'Logout', 'dictTypeData']),
        handleUsernameOrEmail(rule, value, callback) {
            const { state } = this
            const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
            if (regex.test(value)) {
                state.loginType = 0
            } else {
                state.loginType = 1
            }
            callback()
        },
        handleSubmit(e) {
            e.preventDefault()
            const {
                form: { validateFields },
                state,
                customActiveKey,
                Login,
            } = this

            state.loginBtn = true
            const validateFieldsKey = ['account', 'password']
            validateFields(validateFieldsKey, { force: true }, (err, values) => {
                this.loginParams = values
                if (!err) {
                    const loginParams = { ...values }
                    delete loginParams.account
                    loginParams.account = values.account
                    Login(loginParams)
                        .then((res) => this.loginSuccess(res))
                        .catch((err) => this.requestFailed(err))
                        .finally(() => {
                            state.loginBtn = false
                        })
                } else {
                    setTimeout(() => {
                        state.loginBtn = false
                    }, 600)
                }
            })
        },
        loginSuccess(res) {
            // console.log(this.$store.state.app.device)
            if (this.$store.state.app.device === DEVICE_TYPE.MOBILE) {
                this.$router.push({ path: '/mobile/home' })
            } else {
                this.$router.push({ path: '/fileStreamHome' })
            }
            this.isLoginError = false
            // 加載字典所有字典到緩存中
            this.dictTypeData().then((res) => {})
        },
        requestFailed(err) {
            this.accountLoginErrMsg = err
            this.isLoginError = true
        },
    },
}
