var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"body"},[(_vm.$store.state.app.device === _vm.DEVICE_TYPE.MOBILE)?_c('div',{staticClass:"imgs"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("./image/wxMobileLogin.jpg"),"alt":"","srcset":""}})]):_c('div',{staticClass:"login-box"},[_c('div',{staticClass:"login-info"},[_c('div',{staticClass:"login-info-top"},[_vm._m(0),_c('div',{staticClass:"login-info-right"},[_c('div',{staticClass:"login-info-title"},[_vm._v("歡迎登錄公文流轉系統")]),_c('a-form',{ref:"formLogin",staticClass:"user-layout-login",attrs:{"id":"formLogin","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"form-item-margin"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                    'account',
                                    { initialValue: '' },
                                    {
                                        rules: [
                                            { required: true, message: '請輸入帳戶名' },
                                            { validator: _vm.handleUsernameOrEmail } ],
                                        validateTrigger: 'change',
                                    } ]),expression:"[\n                                    'account',\n                                    { initialValue: '' },\n                                    {\n                                        rules: [\n                                            { required: true, message: '請輸入帳戶名' },\n                                            { validator: handleUsernameOrEmail },\n                                        ],\n                                        validateTrigger: 'change',\n                                    },\n                                ]"}],attrs:{"size":"large","type":"text","placeholder":"請輸入您的賬號"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',{staticClass:"form-item-margin"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                    'password',
                                    { initialValue: '' },
                                    { rules: [{ required: true, message: '請輸入密碼' }], validateTrigger: 'blur' } ]),expression:"[\n                                    'password',\n                                    { initialValue: '' },\n                                    { rules: [{ required: true, message: '請輸入密碼' }], validateTrigger: 'blur' },\n                                ]"}],attrs:{"size":"large","type":"password","autocomplete":"false","placeholder":"請輸入您的密碼"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',{staticClass:"form-item-margin1"},[_c('a-button',{staticClass:"login-button",attrs:{"size":"large","type":"primary","htmlType":"submit","shape":"round","loading":_vm.state.loginBtn,"disabled":_vm.state.loginBtn,"block":""}},[_vm._v("登錄")])],1)],1)],1)]),_vm._m(1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-info-left"},[_c('img',{attrs:{"src":require("./image/bgm4.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-info-bottom"},[_c('div',{staticClass:"login-info-tool"},[_c('span',[_vm._v("幫助")]),_c('span',[_vm._v("隱私")]),_c('span',[_vm._v("條款")])]),_c('span',{staticClass:"login-info-web"},[_vm._v("Copyright 2022 貴州遐宇科技服務有限公司 "),_c('a',{attrs:{"href":"https://beian.miit.gov.cn/","data-notranslate":""}},[_vm._v("黔ICP备20006285号-3")]),_vm._v(" All rights reserved. ")])])}]

export { render, staticRenderFns }