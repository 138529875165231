//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            visible: false,
            openFlag: 'Y',
        }
    },
    computed: {
        isExpiredImg() {
            let img1 = require('@/assets/img/login/serviceExpiration.png'),
                img2 = require('@/assets/img/login/trialExpires.png')
            return this.openFlag == 'Y' ? img1 : img2
        },
    },
    methods: {
        open({ openFlag }) {
            this.openFlag = openFlag
            this.visible = false  //true
        },
    },
}
