//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { serverUrl } from '@/mixins/config.js'
import { DEVICE_TYPE } from '@/utils/device'
import { mapActions } from 'vuex'
import isExpiredTipDialog from './components/isExpiredTipDialog.vue'
export default {
  components: {
    isExpiredTipDialog,
  },
  data() {
    return {
      code: null,
      type: null,
      re: '',
      wxOpenUrl: null,
      DEVICE_TYPE,
    }
  },
  created() {
    this.getUrlPramas()
  },
  methods: {
    ...mapActions(['wxLogin', 'dictTypeData']),
    qywxlogin() {
      const para = {
        loginType: this.type,
        authCode: this.code,
      }
      this.wxLogin(para)
        .then((res) => {
        //   王念修改
        //   const { openFlag, isExpired } = res
        //   if (isExpired) {
        //      this.$refs.isExpiredTipDialog.open({ openFlag })
        //      return
        //   }
          if (this.$store.state.app.device === DEVICE_TYPE.MOBILE) {
            const { path, query } = this.getQueryInfo()
            switch (path) {
              case '/fileStream_Approval_Manage':
                this.$router.push({ name: 'mobileApproval', query })
                break
              case '/fileStream_Approval_process':
                this.$router.push({ name: 'showMobileApproval', query })
                break
              case '/fileStream_documentSend':
                this.$router.push({ name: 'mobileBillDetail', query })
                break
              default:
                this.$router.push({ path: '/mobile/home' })
            }
          } else {
            this.$router.push({ path: '/fileStreamHome' })
          }
          // this.$router.push({ path: '/fileStreamHome' })
          // 加載字典所有字典到緩存中
          this.dictTypeData().then((res) => {})
        })
        .catch((err) => {
          console.log('err+++++++++', err)
        })
    },
    getUrlPramas() {
      /**
       * 獲取url 的wx登錄鏈接code,無則 需要微信登錄
       *
       *
       */
      const w = new URLSearchParams(decodeURIComponent(window.location.search))
      const re = w.get('redirect')
      this.re = re
      const aut = new URLSearchParams(re.substring(re.indexOf('?')))
      const code = aut.get('auth_code') || aut.get('code')
      if (aut.get('code')) {
        // 企業微信端
        this.type = 'APP'
      } else if (aut.get('auth_code')) {
        // pc端
        this.type = 'PC'
      }
      this.code = code
      console.log('url===========', window.location.url)
      console.log('code===========', code, this.type, re)
      if (!code) {
        if (re && re != '/') {
          this.wxOpenUrl = encodeURIComponent(`http://${serverUrl}` + re)
        } else {
          this.wxOpenUrl = encodeURIComponent(`http://${serverUrl}/`)
        }
        // this.$message.error('請退出後重新進入應用！')
        return
      }
      this.qywxlogin()
    },
    getQueryInfo() {
      const res = this.re.split('?')
      const query = {}
      const entries = new URLSearchParams(res[1]).entries()
      for (const [key, value] of entries) {
        query[key] = value
      }
      return {
        path: res[0],
        query,
      }
    },
    goLoginWx() {
      console.log('url====', this.wxOpenUrl)
      window.open(
        `https://open.work.weixin.qq.com/wwopen/sso/3rd_qrConnect?appid=wwdbef0913f3a4e57c&redirect_uri=${this.wxOpenUrl}&state=web_login@gyoss9&usertype=member`,
        '_self'
      )
    },
  },
}
